<!-- =========================================================================================
    File Name: Main.vue
    Description: Main layout
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
    Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div
    class="layout--main"
    :class="[
      layoutTypeClass,
      navbarClasses,
      footerClasses,
      { 'no-scroll': isAppPage },
    ]"
  >
    <!-- <vx-tour
      :steps="steps"
      v-if="!disableThemeTour && (windowWidth >= 1200 && mainLayoutType === 'vertical' && verticalNavMenuWidth == 'default')"
    /> -->

    <!-- <the-customizer
      v-if                    = "!disableCustomizer"
      :footerType             = "footerType"
      :hideScrollToTop        = "hideScrollToTop"
      :navbarType             = "navbarType"
      :navbarColor            = "navbarColor"
      :routerTransition       = "routerTransition"
      @toggleHideScrollToTop  = "toggleHideScrollToTop"
      @updateFooter           = "updateFooter"
      @updateNavbar           = "updateNavbar"
      @updateNavbarColor      = "updateNavbarColor"
      @updateRouterTransition = "updateRouterTransition" /> -->

    <v-nav-menu
      :navMenuItems="navMenuItems"
      :logo="navMenuLogo"
      parent=".layout--main"
    />

    <div
      id="content-area"
      :class="[contentAreaClass, { 'show-overlay': bodyOverlay }]"
    >
      <div id="content-overlay" />
      <!-- Navbar -->
      <template v-if="mainLayoutType === 'horizontal' && windowWidth >= 1200">
        <the-navbar-horizontal
          @OpenDoctorRate="showDoctorRate = true"
          @OpenHospitalRate="showHospitalRate = true"
          :DoctorRate="DoctorRate"
          :navMenuItems="navMenuItems"
          :navbarType="navbarType"
          :logo="navMenuLogo"
          :class="[
            { 'text-white': isNavbarDark && !isThemeDark },
            { 'text-base': !isNavbarDark && isThemeDark },
          ]"
        />

        <div style="height: 62px" v-if="navbarType === 'static'"></div>
        <h-nav-menu
          v-if="false && !$acl.check('doctor') && !$acl.check('hospital')"
          :class="[
            { 'text-white': isNavbarDark && !isThemeDark },
            { 'text-base': !isNavbarDark && isThemeDark },
          ]"
          :navMenuItems="navMenuItems"
        />
      </template>

      <template v-else>
        <the-navbar-vertical
          @OpenDoctorRate="showDoctorRate = true"
          @OpenHospitalRate="showHospitalRate = true"
          :DoctorRate="DoctorRate"
          :navbarColor="navbarColor"
          :class="[
            { 'text-white': isNavbarDark && !isThemeDark },
            { 'text-base': !isNavbarDark && isThemeDark },
          ]"
        />
      </template>
      <!-- /Navbar -->
      <!-- style="margin-right:5rem;margin-left:5rem" -->
      <div class="content-wrapper">
        <div class="router-view">
          <div class="router-content">
            <div class="content-area__content">
              <back-to-top
                bottom="5%"
                :right="$vs.rtl ? 'calc(100% - 2.2rem - 38px)' : '30px'"
                visibleoffset="500"
                v-if="!hideScrollToTop"
              >
                <vs-button
                  icon-pack="feather"
                  icon="icon-arrow-up"
                  class="shadow-lg btn-back-to-top"
                />
              </back-to-top>
              <div>
                <div
                  v-if="windowWidth >= 1200"
                  style="
                    height: 40px;
                    width: 100%;
                    background: rgb(235, 238, 243);
                    margin-top: 75px;
                  "
                ></div>

                <vs-row vs-w="12">
                  <vs-col
                    vs-type="flex"
                    vs-sm="0"
                    :vs-w="$route.path != '/' ? 1 : 0"
                  >
                    <ul style="height: 109vh; width: 100%" id="navbarStick">
                      <li v-if="$route.path.toLowerCase() != '/patient/home'">
                        <side-bar style="height: 48vh; margin-bottom: 5px" />
                      </li>
                      <li>
                        <side-bar
                          :height="
                            $route.path.toLowerCase() != '/patient/home'
                              ? 25
                              : 70
                          "
                          :style="{
                            height:
                              $route.path.toLowerCase() != '/patient/home'
                                ? '60vh'
                                : '107vh',
                          }"
                          :title="
                            $route.path != '/' ? $route.meta.pageTitle : ''
                          "
                        />
                      </li>
                    </ul>
                  </vs-col>
                  <vs-col
                    style="background: white"
                    vs-type="flex"
                    vs-sm="12"
                    :vs-w="$route.path != '/' ? 11 : 12"
                  >
                    <div
                      :class="$route.path != '/' ? 'mr-2 ml-2' : ''"
                      class="w-full"
                    >
                      <!-- <div v-if="windowWidth>500" style="height:40px;width:100%;background:rgb(235, 238, 243);margin-top: 75px;"
                  :style="[$route.path!='/'?{'margin-top':'5.1rem'}:{'margin-top':'4rem'}]">  </div>

                   <div v-if="windowWidth<=500"  :style="[$route.path!='/'?{'margin-top':'5.1rem'}:{'margin-top':'4rem'}]"></div> -->

                      <transition :name="routerTransition" mode="out-in">
                        <router-view
                          style="margin: auto; background: white"
                          :style="[
                            $route.path != '/'
                              ? {
                                  width: windowWidth >= 1200 ? '95%' : '100%',
                                  'margin-top':
                                    windowWidth >= 1200 ? '2.1rem' : '5.2rem',
                                  'padding-left':
                                    windowWidth >= 1200 ? '3rem' : '1.8rem',
                                  'padding-right':
                                    windowWidth >= 1200 ? '3rem' : '1.8rem',
                                  'padding-bottom': '3rem',
                                }
                              : {},
                          ]"
                          @changeRouteTitle="changeRouteTitle"
                          @setAppClasses="
                            (classesStr) => $emit('setAppClasses', classesStr)
                          "
                        />
                      </transition>
                    </div>
                  </vs-col>
                </vs-row>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <the-footer /> -->
      <!-- <div
        id="footer"
        :style="{
          'background-image': `url(${require('@/assets/images/home-assets/footer.png')})`,
          backgroundRepeat: 'round',
          backgroundSize: 'cover',
        }"
      >
        <vs-row vs-w="12" vs-align="flex-start" class="pt-10 pb-10">
          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-sm="12"
            vs-w="4"
          >
            <ul>
              <li>
                <img src="@/assets/images/footer_logo.png" />
              </li>
              <li>
                <div class="vx-row mb-5">
                  <img
                    src="@/assets/images/WhatsApp.png"
                    class="ml-1 mr-1 socialImage"
                    style="width: 50px; height: 50px;"
                  />
                  <img
                    src="@/assets/images/PhoneIcon.png"
                    class="ml-1 mr-1 socialImage"
                    style="width: 50px; height: 50px;"
                  />

                  <div class="mt-3 ml-2 mr-2">

                    <p class="greenColor">Brand of Cure Journey Portal</p>

                    <p class="greenColor">www.cure-journey.com</p>
                    <p class="greenColor">Phone : +971 4 517 7676</p>
                    <p class="greenColor">WhatsApp : +201222246224</p>
                  </div>
                </div>
              </li>
              <li>
                 <h1 style="color:white">{{$t('PRIVACYPOLICY')}} </h1>
           <div class="align-items-center" style="height: 5px; width: 25%; background: #00C524; margin: 2px;"></div>

              </li>
              <li>
                <div class="vx-row align-items-center">
                  <feather-icon icon="ChevronRightIcon" />
                  <a
                    style="margin-top: 2px"
                    :href="
                      $vs.rtl ? 'TermsAndCondetionsAr' : 'TermsAndCondetions'
                    "
                    >{{ $t("TermsAndConditions") }}</a
                  >
                </div>
              </li>

              <li>
                <div class="vx-row">
                  <feather-icon icon="ChevronRightIcon" />
                  <a
                    style="margin-top: 2px"
                    :href="$vs.rtl ? 'AboutUs' : 'AboutUs'"
                    >{{ $t("AboutUs") }}</a
                  >
                </div>
              </li>
            </ul>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-sm="12"
            vs-w="4"
          >
            <ul>
              <li>
                <h1 style="color:white">{{$t('COLLABORATEFotter')}} </h1>
               <div  class="vx-row align-items-center" style="height: 5px; width: 15%; background: #00C524; margin: 2px;">

               </div>

              </li>
              <li v-for="(item, index) in collaborateMenu" :key="index">
                <div class="vx-row align-items-center">
                  <feather-icon icon="ChevronRightIcon" />
                  <a style="margin-top: 2px" :href="item.href">{{
                    $t(item.name)
                  }}</a>
                </div>
              </li>
              <li>
                <div>
                  <span
                    style="color: white; font-size: 13px; text-align: center"
                    >{{ $t("AcceptPaymentText") }}
                  </span>
                  <br/>

                  <span
                    style="color: white; font-size: 13px; text-align: center"
                    >{{ $t("AcceptPaymentTextSecond") }}
                  </span>
                  <div
                    class="vx-row pt-3 pb-3"
                    style="text-align: center; justify-content: center"
                  >
                    <img
                      src="@/assets/images/Group 2.png"
                      style="max-width: 60px"
                      alt=""
                      class="ml-3"
                    />

                    <img
                      src="@/assets/images/Group 1.png"
                      style="max-width: 60px"
                      alt=""
                      class="ml-3"
                    />
                    <img
                      src="@/assets/images/Group 3.png"
                      style="max-width: 60px"
                      alt=""
                      class="ml-3"
                    />
                    <img
                      src="@/assets/images/Group 4.png"
                      style="max-width: 60px"
                      alt=""
                      class="ml-3"
                    />
                  </div>
                </div>
              </li>
            </ul>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-sm="12"
            vs-w="4"
          >
            <ul style="margin: 5px">
              <li>
                  <h1 style="color:white">{{$t('PATIENTCAREFOOTER')}} </h1>
                  <div class="align-items-center" style="height: 5px; width: 35%; background: #00C524; margin: 2px;"></div>

              </li>
              <li v-for="(item, index) in careMenu" :key="index">
                <div class="vx-row">
                  <feather-icon icon="ChevronRightIcon" />
                  <a
                    style="margin-top: 2px"
                    :href="$vs.rtl ? item.Arhref : item.href"
                    >{{ $t(item.name) }}</a
                  >
                </div>
              </li>
              <li>
                <h4>{{ $t("FOLLOWUS") }}</h4>
                <div style="margin-top: 5px">
                  <a href="https://www.facebook.com/Doclinia" target="_blank">
                    <img
                      src="@/assets/images/social/facebook.png"
                      class="ml-1 mr-1 socialImage"
                      alt="Facebook"
                    />
                  </a>
                  <a href="https://twitter.com/doclinia" target="_blank">
                    <img
                      src="@/assets/images/social/twitter.png"
                      class="socialImage"
                      alt="https://twitter.com/doclinia"
                      href="https://twitter.com/doclinia"
                    />
                  </a>
                  <a href=" https://www.linkedin.com/company/doclinia/" target="_blank">
                    <img
                      src="@/assets/images/social/linked.png"
                      class="ml-1 mr-1 socialImage"
                      alt="Linkedin"
                    />
                  </a>

                  <a
                    href="https://www.instagram.com/doclinia.uae/"
                    target="_blank"
                  >
                    <img
                      src="@/assets/images/social/instaIcon.png"
                      class="ml-1 mr-1 socialImage"
                      alt="Instagram"
                      style="max-width: 11%"
                    />
                  </a>
                </div>
              </li>
            </ul>
          </vs-col>
        </vs-row>
        <div class="darkBackColor">
          <span style="font-size: 10px"
            >Cure Journey Portal,Licence ID 955764 ,Souk Al Bahr, Saha offices
            C, office 202, Dubai, UAE</span
          >
        </div>
        <div class="text-center darkBackColor" style="height: 40px">
          <a
            class="pt-3"
            href="https://i-valley.com/"
            target="_blank"
            rel="noreferrer noopener"
          >
            POWERED BY I-VALLEY
          </a>
        </div>
      </div> -->
    </div>

    <vs-popup title="" :active.sync="showDoctorRate" v-if="showDoctorRate">
      <doctorRate
        :DoctorRate="DoctorRate"
        v-if="showDoctorRate"
        @close="showDoctorRate = false"
      ></doctorRate>
    </vs-popup>

    <vs-popup title="" :active.sync="showHospitalRate" v-if="showHospitalRate">
      <hospitalRate
        :hospitalRate="DoctorRate"
        v-if="showHospitalRate"
        @close="showHospitalRate = false"
      ></hospitalRate>
    </vs-popup>
  </div>
</template>

<script>
import BackToTop from "vue-backtotop";
import HNavMenu from "@/layouts/components/horizontal-nav-menu/HorizontalNavMenu.vue";
import navMenuItems from "@/layouts/components/horizontal-nav-menu/navMenuItems.js";
// import TheCustomizer       from '@/layouts/components/customizer/TheCustomizer.vue'
import TheNavbarHorizontal from "@/layouts/components/navbar/TheNavbarHorizontal.vue";
import TheNavbarVertical from "@/layouts/components/navbar/TheNavbarVertical.vue";
import TheFooter from "@/layouts/components/TheFooter.vue";
import themeConfig from "@/../themeConfig.js";
import VNavMenu from "@/layouts/components/vertical-nav-menu/VerticalNavMenu.vue";
import IdleSessionLogout from "./../../views/user/IdleSessionLogout.vue";
import doctorRate from "@/views/doctor/DoctorRate.vue";
import hospitalRate from "@/views/hospital/HospitalRate.vue";
export default {
  components: {
    doctorRate,
    BackToTop,
    HNavMenu,
    // TheCustomizer,
    TheFooter,
    TheNavbarHorizontal,
    TheNavbarVertical,
    VNavMenu,
    IdleSessionLogout,
    hospitalRate,
  },
  data() {
    return {
      collaborateMenu: [
        {
          href: "/CollaborateDoctor",
          name: "Doctors",
        },
        {
          href: "/CollaborateHospital",
          name: "Hospitals",
        },
        {
          href: "/CollaborateStrategic",
          name: "Strategic",
        },
        //   {
        //    href:"/Collaborate",
        //    name:"Collaborators"
        //  },
        {
          href: "/CollaborateJoinOurTeam",
          name: "JoinOurTeam",
        },
        // {
        //   href:"/AboutUs",
        //   name:"AboutUs"
        // },
      ],
      careMenu: [
        {
          Arhref: "/pages/ContactUs",
          href: "/pages/ContactUs",
          name: "ContactUs",
        },
        {
          Arhref: "/Ar/FAQ",
          href: "/FAQ",
          name: "FAQ",
        },
        {
          href: "/PatientWaiver",
          Arhref: "/Ar/PatientWaiver",
          name: "PatientWaiver",
        },
      ],
      showHospitalRate: false,
      showDoctorRate: false,
      DoctorRate: {},
      showNotification: false,
      disableCustomizer: themeConfig.disableCustomizer,
      disableThemeTour: themeConfig.disableThemeTour,
      dynamicWatchers: {},
      footerType: themeConfig.footerType || "static",
      hideScrollToTop: themeConfig.hideScrollToTop,
      isNavbarDark: false,
      navbarColor: "darkturquoise",
      navbarType: themeConfig.navbarType || "floating",
      navMenuItems,
      routerTransition: themeConfig.routerTransition || "none",
      routeTitle: this.$route.meta.pageTitle,
    };
  },
  watch: {
    $route() {
      this.routeTitle = this.$route.meta.pageTitle;
    },
    isThemeDark() {
      const color = "darkturquoise";
      this.updateNavbarColor(color);
    },
    "$store.state.mainLayoutType"(val) {
      this.setNavMenuVisibility(val);
      this.disableThemeTour = true;
    },
  },
  computed: {
    windowWidth() {
      return this.$store.state.windowWidth;
    },
    navMenuLogo() {
      return require(`@/assets/images/logo/logo.png`);
    },
    isIdle() {
      return this.$store.state.idleVue.isIdle;
    },
    bodyOverlay() {
      return this.$store.state.bodyOverlay;
    },
    contentAreaClass() {
      if (this.mainLayoutType === "vertical") {
        if (this.verticalNavMenuWidth === "default") {
          return "content-area-reduced";
        } else if (this.verticalNavMenuWidth === "reduced") {
          return "content-area-lg";
        } else {
          return "content-area-full";
        }
      } else {
        return "content-area-full";
      }
    },
    footerClasses() {
      return {
        "footer-hidden": this.footerType === "hidden",
        "footer-sticky": this.footerType === "sticky",
        "footer-static": this.footerType === "static",
      };
    },
    isAppPage() {
      return this.$route.meta.no_scroll;
    },
    isThemeDark() {
      return this.$store.state.theme === "dark";
    },
    layoutTypeClass() {
      return `main-${this.mainLayoutType}`;
    },
    mainLayoutType() {
      return this.$store.state.mainLayoutType;
    },
    navbarClasses() {
      return {
        "navbar-hidden": this.navbarType === "hidden",
        "navbar-sticky": this.navbarType === "sticky",
        "navbar-static": this.navbarType === "static",
        "navbar-floating": this.navbarType === "floating",
      };
    },
    verticalNavMenuWidth() {
      return this.$store.state.verticalNavMenuWidth;
    },
    windowWidth() {
      return this.$store.state.windowWidth;
    },
  },
  methods: {
    changeRouteTitle(title) {
      this.routeTitle = title;
    },
    updateNavbar(val) {
      // if (val === "static") {
      //   this.updateNavbarColor("#fff");
      // }
      this.updateNavbarColor("darkturquoise");
      this.navbarType = val;
    },
    updateNavbarColor() {
      this.navbarColor = "darkturquoise";
      // if (val === "#fff") {
      //   this.isNavbarDark = false;
      // } else {
      //   this.isNavbarDark = true;
      // }
    },
    updateFooter(val) {
      this.footerType = val;
    },
    updateRouterTransition(val) {
      this.routerTransition = val;
    },
    setNavMenuVisibility(layoutType) {
      if (
        (layoutType === "horizontal" && this.windowWidth >= 1200) ||
        (layoutType === "vertical" && this.windowWidth < 1200)
      ) {
        this.$store.commit("TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE", false);
        this.$store.dispatch("updateVerticalNavMenuWidth", "no-nav-menu");
      } else {
        this.$store.commit("TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE", true);
      }
    },
    toggleHideScrollToTop(val) {
      this.hideScrollToTop = val;
    },
  },
  created() {
    this.updateNavbarColor("darkturquoise");
    this.setNavMenuVisibility(this.$store.state.mainLayoutType);

    // Dynamic Watchers for tour
    // Reason: Once tour is disabled it is not required to enable it.
    // So, watcher is required for just disabling it.
    this.dynamicWatchers.windowWidth = this.$watch(
      "$store.state.windowWidth",
      (val) => {
        if (val < 1200) {
          this.disableThemeTour = true;
          this.dynamicWatchers.windowWidth();
        }
      }
    );

    this.dynamicWatchers.verticalNavMenuWidth = this.$watch(
      "$store.state.verticalNavMenuWidth",
      () => {
        this.disableThemeTour = true;
        this.dynamicWatchers.verticalNavMenuWidth();
      }
    );

    this.dynamicWatchers.rtl = this.$watch("$vs.rtl", () => {
      this.disableThemeTour = true;
      this.dynamicWatchers.rtl();
    });
  },
  mounted() {
    window.onscroll = function () {
      myFunction();
    };
    function myFunction() {
      var navbar = document.getElementById("navbarStick");
      var sticky = navbar.offsetTop;
      if (window.pageYOffset >= sticky) {
        navbar.classList.add("sticky");
      } else {
        navbar.classList.remove("sticky");
      }
    }
  },
  beforeDestroy() {
    Object.keys(this.dynamicWatchers).map((i) => {
      this.dynamicWatchers[i]();
      delete this.dynamicWatchers[i];
    });
  },
};
</script>
<style lang="scss">
.router-view {
  @media (max-width: 576px) {
    padding: unset !important;
  }
}
</style>

<style>
.vs__dropdown-menu {
  text-overflow: ellipsis;
  width: 600px;
}
.con-vs-card {
  border-radius: unset;
}
.vx-card {
  border-radius: unset;
}
.vs-button {
  border-radius: unset;
}
.sticky {
  position: fixed;
  top: 70px;
  width: 100%;
}

#footer .vx-row {
  margin: -7px;
}
#footer a {
  color: white;
}
#footer span {
  color: white;
}
#footer h4 {
  color: white;
}
#footer p {
  color: white;
}
#footer ul li {
  margin-top: 15px;
}
.vs__selected-options {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  flex-wrap: unset;
}
.router-content {
  width: 100% !important;
}
.router-view {
  padding: unset;
}
.navbar-floating .router-content {
  margin-top: unset;
}
.main-horizontal.navbar-floating .router-content {
  margin-top: unset;
}
</style>
<style>
.con-vs-popup .vs-popup {
  box-shadow: unset;
}
::-webkit-scrollbar {
  height: 5px;
  width: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.material-icons {
  font-family: "Material Icons" !important;
}
.vs-icon {
  font-family: "Material Icons" !important;
}
.feather {
  font-family: "feather" !important;
}
.rounded-lg {
  border-radius: 8.5rem !important;
}
.router-content {
  width: 85%;
  /* text-align: center; */
  margin-left: auto;
  margin-right: auto;
}
.vs__dropdown-toggle {
  background: white;
  border: 2px solid #454a62 !important;
  border-radius: 9px;
}
.vs-inputx {
  border: 2px solid #454a62 !important;
  border-radius: 9px;
}
.margin-auto {
  margin: auto;
}
.vs-popup {
  background: transparent !important;
}
.vs-popup--header {
  display: none;
}

/* .vs-popup--content {
  margin-top: 0px;
  padding-top: 0px !important;
} */
.vue-star-rating-rating-text {
  display: none;
}

.MyPopClose {
  position: relative;
  margin: -17px 5px -3px;
  text-align: end;
}

@font-face {
  font-family: "TheSansPlain";
  src: local("futura"),
    url(/../../assets/fonts/futura/TheSans-Plain.otf) format("truetype");
}
</style>
