<template>
  <div>
    <div class="the-navbar__user-meta flex items-center" v-if="activeUserInfo">
      <div class="text-right leading-tight hidden sm:block">
        <p class="font-semibold">{{ firstName }} {{ lastName }}</p>
        <small>{{ $t(accounttype) }}</small>
      </div>

      <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">
        <div class="con-img ml-3">
          <img
                v-if="activeUserImg"
                key="onlineImg"
                :src="baseURL+activeUserImg"
                alt="user-img"
                width="40"
                height="40"
                class="rounded-full shadow-md cursor-pointer block"
              />

              <img
                v-else
                key="onlineImg"
                src="@/assets/images/logo/logo.png"
                alt="user-img"
                width="40"
                height="40"
                class="rounded-full shadow-md cursor-pointer block"
              />
        </div>

          <vs-dropdown-menu class="vx-navbar-dropdown">
              <ul style="min-width: 9rem;">
                <li
                  class="flex py-2 px-4 cursor-pointer text-primary hover:bg-primary  hover:text-white"
                  @click="GoToProfile()"
                >
                  <feather-icon icon="UserIcon" svgClasses="w-4 h-4" />
                  <span class="ml-2  ">Profile</span>
                </li>

                <!-- <li
                  class="flex py-2 px-4 cursor-pointer text-primary hover:bg-primary  hover:text-white"
                  @click="$router.push('/apps/chat').catch(() => {})"
                >
                  <feather-icon icon="MessageSquareIcon" svgClasses="w-4 h-4" />
                  <span class="ml-2 ">Messages</span>
                </li>
                
                <li
                  class="flex py-2 px-4 cursor-pointer   text-primary hover:bg-primary  hover:text-white"
                  @click="
                    $router.push('/apps/eCommerce/wish-list').catch(() => {})
                  "
                >
                  <feather-icon icon="HeartIcon" svgClasses="w-4 h-4" />
                  <span class="ml-2">Favourite</span>
                </li> -->
                
                <li
                v-if="$store.state.AppActiveUser.Patient && $store.state.AppActiveUser.Patient.ID>0"
                  class="flex py-2 px-4 cursor-pointer  text-primary hover:bg-primary  hover:text-white"
                  @click="
                    $router.push('/Patient/PatientSetting').catch(() => {})
                  "
                >
                  <feather-icon icon="SettingsIcon" svgClasses="w-4 h-4" />
                  <span class="ml-2">Setting</span>
                </li>
                
                <li
                      v-if="$store.state.AppActiveUser.Doctor && $store.state.AppActiveUser.Doctor.ID>0"
                  class="flex py-2 px-4 cursor-pointer  text-primary hover:bg-primary  hover:text-white"
                  @click="
                    $router.push('/doctor/DoctorSetting').catch(() => {})
                  "
                >
                  <feather-icon icon="SettingsIcon" svgClasses="w-4 h-4" />
                  <span class="ml-2">Setting</span>
                </li>
                   
                   <li
                      v-if="$store.state.AppActiveUser.Hospital && $store.state.AppActiveUser.Hospital.ID>0"
                  class="flex py-2 px-4 cursor-pointer  text-primary hover:bg-primary  hover:text-white"
                  @click="
                    $router.push('/hospital/HospitalSetting').catch(() => {})
                  "
                >
                  <feather-icon icon="SettingsIcon" svgClasses="w-4 h-4" />
                  <span class="ml-2">Setting</span>
                </li>
                <vs-divider class="m-1"></vs-divider>

                <li
                  class="flex py-2 px-4 cursor-pointer text-primary hover:bg-primary  hover:text-white"
                  @click="logout"
                >
                  <feather-icon icon="LogOutIcon" svgClasses="w-4 h-4" />
                  <span class="ml-2">Logout</span>
                </li>
              </ul>
            </vs-dropdown-menu>
      </vs-dropdown>
    </div>

    <div class="the-navbar__user-meta flex items-center" v-else>
      <div class="text-right leading-tight hidden sm:block">
        <p class="font-semibold">{{ $t("LOGIN") }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { domain } from "@/gloabelConstant.js";
export default {
  data() {
    return { baseURL:domain,};
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
    firstName() {
      return this.$store.state.AppActiveUser.firstName;
    },
    lastName() {
      return this.$store.state.AppActiveUser.lastName;
    },
    accounttype() {
      return this.$store.state.AppActiveUser.accounttype;
    },
    ProfilePic() {
      return this.$store.state.AppActiveUser.ProfilePic;
    },
      activeUserImg() {
      debugger
      if(this.$store.state.AppActiveUser.Doctor&&this.$store.state.AppActiveUser.Doctor.ID>0)
         return this.$store.state.AppActiveUser.Doctor.ProfileImagePath;
      if(this.$store.state.AppActiveUser.Patient&&this.$store.state.AppActiveUser.Patient.ID>0)
         return this.$store.state.AppActiveUser.Patient.ProfileImagePath;
      if(this.$store.state.AppActiveUser.Hospital&&this.$store.state.AppActiveUser.Hospital.ID>0)
         return this.$store.state.AppActiveUser.Hospital.ImagePath;
    },
  },
  methods: {
      GoToProfile()
    {
      if(this.$acl.check("patient"))
        this.$router.push('/patient/home').catch(() => {});
     else if(this.$acl.check("hospital"))
        this.$router.push('/hospital/Home').catch(() => {});
     else 
        this.$router.push('/doctor/home').catch(() => {});

    },
    logout() {
      this.$store.dispatch("auth/logout");
      // localStorage.removeItem('accessToken')
      // This is just for demo Purpose. If user clicks on logout -> redirect
      this.$router.push("/pages/login").catch(() => {});
    },

    mounted() {
      if (!this.activeUserInfo) {
        this.$router.push("user/login/login");
      }
    }
  }
};
</script>
<style>
.routerLink {
  text-decoration: none;
}
</style>
