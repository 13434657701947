<template>
  <div class="relative">
    <div class="vx-navbar-wrapper navbar-full p-0">
      <!-- <vs-navbar
        class="navbar-custom navbar-skelton"
        style="background-image: url('http://173.249.43.159:89/navbarheader.png')"
        :class="navbarClasses"
        :style="navbarStyle"
        :color="navbarColor"
      > -->
      <vs-navbar
        class="navbar-custom navbar-skelton"
        :class="navbarClasses"
        :style="navbarStyle"
        :color="navbarColor"
      >
        <feather-icon
          class="sm:inline-flex xl:hidden cursor-pointer mr-1"
          icon="MenuIcon"
         style="color:#fff"  
         v-if="$acl.check('patient')||$acl.check('notAuthenticated')"
          @click.stop="showSidebar"
        ></feather-icon>
        <!-- <bookmarks :navbarColor="navbarColor" v-if="windowWidth >= 992" /> -->

        <!-- <router-link 
          tag="div"
          to="/" 
          class="vx-logo cursor-pointer mx-auto flex items-center"
        > -->
          <!-- v-if="windowWidth >= 992" -->
        <div
          v-if="false"
          class="absolute left-0 top-0 flex cursor-pointer mx-auto mt-4 sm:ml-6 md:ml-6 lg:ml-6 xl:ml-6 ml-20"
        >
          <transition :name="routerTransition"  >
            <div
          
              v-if="$route.meta.breadcrumb || $route.meta.pageTitle"
              class="router-header flex flex-wrap items-center mb-6"
            >
              <div
                class="content-area__heading"
                :class="{
                  'pr-4 border-0 md:border-r border-solid border-grey-light':
                    $route.meta.breadcrumb
                }"
              >
                <h2 class="mb-1">{{ $t(routeTitle) }}</h2>
              </div>

              <!-- BREADCRUMB -->
              <vx-breadcrumb
                class="ml-4 md:block hidden"
                v-if="$route.meta.breadcrumb"
                :route="$route"
                :isRTL="$vs.rtl"
              />
            </div>
          </transition>
        </div>
     
        <router-link
          tag="div"
          to="/"
          class="vx-logo cursor-pointer mx-auto flex items-center"
        >
        </router-link>
        
        <i18n style="padding-right: 20px; padding-left: 20px;"  />
        
      <div  v-if="activeUserInfo.displayName">
       
          <vs-dropdown
            vs-custom-content
            vs-trigger-click
            class="cursor-pointer"
          >
            <div class="con-img ml-3">
              <img
                v-if="activeUserImg"
                key="onlineImg"
                :src="baseURL+activeUserImg"
                alt="user-img"
                width="40"
                height="40"
                class="rounded-full shadow-md cursor-pointer block"
              />

              <img
                v-else
                key="onlineImg"
                src="@/assets/images/logo/logo.png"
                alt="user-img"
                width="40"
                height="40"
                class="rounded-full shadow-md cursor-pointer block"
              />
            
            </div>
            <vs-dropdown-menu class="vx-navbar-dropdown">
              <ul style="min-width: 9rem;">
                <li
                  class="flex py-2 px-4 cursor-pointer text-primary hover:bg-primary  hover:text-white"
                  @click="GoToProfile()"
                >
                  <feather-icon icon="UserIcon" svgClasses="w-4 h-4" />
                  <span class="ml-2  ">Profile</span>
                </li>

             
                 <li
                v-if="$store.state.AppActiveUser.Hospital && $store.state.AppActiveUser.Hospital.ID>0"
                  class="flex py-2 px-4 cursor-pointer  text-primary hover:bg-primary  hover:text-white"
                  @click="gotoEditProfile"
                >
                  <feather-icon icon="InfoIcon" svgClasses="w-4 h-4" />
                  <span class="ml-2">Hospital Info</span>
                </li>
                <li
                v-if="$store.state.AppActiveUser.Patient && $store.state.AppActiveUser.Patient.ID>0"
                  class="flex py-2 px-4 cursor-pointer  text-primary hover:bg-primary  hover:text-white"
                  @click="
                    $router.push('/Patient/PatientSetting').catch(() => {})
                  "
                >
                  <feather-icon icon="SettingsIcon" svgClasses="w-4 h-4" />
                  <span class="ml-2">Setting</span>
                </li>
 <li
                      v-if="$store.state.AppActiveUser.Doctor && $store.state.AppActiveUser.Doctor.ID>0"
                  class="flex py-2 px-4 cursor-pointer  text-primary hover:bg-primary  hover:text-white"
                  @click="
                    $router.push('/doctor/DoctorSetting').catch(() => {})
                  "
                >
                  <feather-icon icon="SettingsIcon" svgClasses="w-4 h-4" />
                  <span class="ml-2">Setting</span>
                </li>

                <vs-divider class="m-1"></vs-divider>

                <li
                  class="flex py-2 px-4 cursor-pointer text-primary hover:bg-primary  hover:text-white"
                  @click="logout"
                >
                  <feather-icon icon="LogOutIcon" svgClasses="w-4 h-4" />
                  <span class="ml-2">Logout</span>
                </li>
              </ul>
            </vs-dropdown-menu>
          </vs-dropdown>
        
         <vs-dropdown
            vs-custom-content
            vs-trigger-click
            class="cursor-pointer ml-5"
          >
            <feather-icon
              icon="BellIcon"
              class="cursor-pointer mt-1 sm:mr-6 mr-2 "
              style="color:#fff" 
              :badge=" notifications.filter((b)=>b.IsRead==false).length"
            ></feather-icon>
            <vs-dropdown-menu
              class="notification-dropdown dropdown-custom vx-navbar-dropdown"
            >
              <div
                class="notification-top text-center p-5 bg-primary text-white"
              >
                <h3 class="text-white">{{ notifications.filter((b)=>b.IsRead==false).length }} {{$t('New')}}</h3>
                <p class="opacity-75">App Notifications</p>
              </div>

              <VuePerfectScrollbar
                ref="mainSidebarPs"
                class="scroll-area--nofications-dropdown p-0 mb-2"
                :settings="settings"
              >
                <ul class="bordered-items">
                  <li
                    v-for="ntf in notifications"
                    :key="ntf.index" :style="[ntf.IsRead == false? {'backgroundColor':'#e8dfdf'}:'']" @click="openNotification(ntf)"
                    class="flex justify-between px-4 py-4 notification cursor-pointer"
                  >
                    <div class="flex items-start">
                      <feather-icon
                        :icon="ntf.icon"
                        :svgClasses="[
                          `text-${ntf.category}`,
                          'stroke-current mr-1 h-6 w-6'
                        ]"
                      ></feather-icon>
                      <div class="mx-2">
                        <span
                          class="font-medium block notification-title"
                          :class="[`text-${ntf.category}`]"
                          >{{ ntf.Title }}</span
                        >
                        <small>{{ ntf.Message }}</small>
                      </div>
                    </div>
                    <small class="mt-1 whitespace-no-wrap">{{
                      elapsedTime(new Date(ntf.CreatedDate))
                    }}</small>
                  </li>
                </ul>
              </VuePerfectScrollbar>
           
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>
          <div v-else>
          <vs-button id="greenLoginColor"  style="background:#00C524!important" @click="GoToLogin()"> {{$t("Login")}}</vs-button>
        </div>

      </vs-navbar>

    </div>
  </div>
</template>

<script>
import I18n from "./components/I18n.vue";
import ProfileDropDown from "./components/ProfileDropDown.vue";
import themeConfig from "@/../themeConfig.js";

export default {
  name: "the-navbar-horizontal",
  props: {},
  data() {
    return {
      routeTitle: this.$route.meta.pageTitle,
      routerTransition: themeConfig.routerTransition || "none",
       settings: {
        // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: 0.6
      },
    };
  },
  watch: {
    $route() {
      this.routeTitle = this.$route.meta.pageTitle;
    }
  },
  components: {
    // Logo,
    // Bookmarks,
    I18n,
    // SearchBar,
    // CartDropDown,
    // NotificationDropDown,
    ProfileDropDown
  },
  computed: {
      activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
      notifications()
    {
      return this.$store.state.notifications
    },
    activeUserInfo() {
      // debugger;
      return this.$store.state.AppActiveUser;
    },
    user_Email() {
      // debugger;
      return this.activeUserInfo.Email;
    },
    activeUserImg() {
      // debugger;
      return this.$store.state.AppActiveUser.photoURL;
    },
    navbarColor() {
      let color = "#004477";
      // if (this.navbarType === "sticky") {
      //   color = "#f7f7f7";
      // } else if (this.navbarType === "static") {
      //   if (this.scrollY < 50) {
      //     color = "#f7f7f7";
      //   }
      // }

      // if (this.isThemedark === "dark") {
      //   if (color === "#fff") {
      //     color = "#10163a";
      //   } else {
      //     color = "#262c49";
      //   }
      // }

      return color;
    },
    isThemedark() {
      return this.$store.state.theme;
    },
    navbarStyle() {
      return this.navbarType === "static"
        ? { transition: "all .25s ease-in-out" }
        : {};
    },
    navbarClasses() {
      return this.scrollY > 5 && this.navbarType === "static"
        ? null
        : "d-theme-dark-light-bg shadow-none";
    },
    scrollY() {
      return this.$store.state.scrollY;
    },
    verticalNavMenuWidth() {
      return this.$store.state.verticalNavMenuWidth;
    },
    windowWidth() {
      return this.$store.state.windowWidth;
    }
  },
  methods: {
     openNotification(notification)
    {
      debugger
      this.checkNotificationAsRead(notification.ID);
      this.notifications.splice(0,1);
     
   
      if(notification.NotificationTypeID==22)
      {
             this.DoctorRate.DoctorID=notification.DoctorID;
           this.DoctorRate.PatientReserviedSessionID=notification.PatientReserviedSessionID;

             this.$emit('OpenDoctorRate');
      }
      else if(notification.NotificationTypeID==23)
      {
         this.DoctorRate.HospitalID = notification.HospitalID;
         this.DoctorRate.PatientReserviedSurgeryID = notification.PatientReseriedSurgeryID;

          this.$emit('OpenHospitalRate');
      }
      else
        this.$router.push(notification.ReturnInfo);

    },
      GoToProfile()
    {
      if(this.$acl.check("patient"))
        this.$router.push('/patient/home').catch(() => {});
     else if(this.$acl.check("hospital"))
        this.$router.push('/hospital/Home').catch(() => {});
     else 
        this.$router.push('/doctor/home').catch(() => {});

    },
     logout() {
      
      this.$acl.change("anonymous")
      this.$store.dispatch("auth/logout");
     
      this.$router.push("/pages/login").then(()=>{
              location.reload();
      }).catch(() => {});
    },
      GoToLogin() {
      this.$router.push("/pages/login");
    },
      elapsedTime(startTime) {
      let x = new Date(startTime);
      let now = new Date();
      var timeDiff = now - x;
      timeDiff /= 1000;

      var seconds = Math.round(timeDiff);
      timeDiff = Math.floor(timeDiff / 60);

      var minutes = Math.round(timeDiff % 60);
      timeDiff = Math.floor(timeDiff / 60);

      var hours = Math.round(timeDiff % 24);
      timeDiff = Math.floor(timeDiff / 24);

      var days = Math.round(timeDiff % 365);
      timeDiff = Math.floor(timeDiff / 365);

      var years = timeDiff;

      if (years > 0) {
        return years + (years > 1 ? " Years " : " Year ") + "ago";
      } else if (days > 0) {
        return days + (days > 1 ? " Days " : " Day ") + "ago";
      } else if (hours > 0) {
        return hours + (hours > 1 ? " Hrs " : " Hour ") + "ago";
      } else if (minutes > 0) {
        return minutes + (minutes > 1 ? " Mins " : " Min ") + "ago";
      } else if (seconds > 0) {
        return seconds + (seconds > 1 ? " sec ago" : "just now");
      }

      return "Just Now";
    },
    user_displayName() {
      return this.activeUserInfo.displayName;
    },
    showSidebar() {
      this.$store.commit("TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE", true);
    }
  }
};
</script>
