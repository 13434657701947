var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"layout--main",class:[
    _vm.layoutTypeClass,
    _vm.navbarClasses,
    _vm.footerClasses,
    { 'no-scroll': _vm.isAppPage },
  ]},[_c('v-nav-menu',{attrs:{"navMenuItems":_vm.navMenuItems,"logo":_vm.navMenuLogo,"parent":".layout--main"}}),_c('div',{class:[_vm.contentAreaClass, { 'show-overlay': _vm.bodyOverlay }],attrs:{"id":"content-area"}},[_c('div',{attrs:{"id":"content-overlay"}}),(_vm.mainLayoutType === 'horizontal' && _vm.windowWidth >= 1200)?[_c('the-navbar-horizontal',{class:[
          { 'text-white': _vm.isNavbarDark && !_vm.isThemeDark },
          { 'text-base': !_vm.isNavbarDark && _vm.isThemeDark },
        ],attrs:{"DoctorRate":_vm.DoctorRate,"navMenuItems":_vm.navMenuItems,"navbarType":_vm.navbarType,"logo":_vm.navMenuLogo},on:{"OpenDoctorRate":function($event){_vm.showDoctorRate = true},"OpenHospitalRate":function($event){_vm.showHospitalRate = true}}}),(_vm.navbarType === 'static')?_c('div',{staticStyle:{"height":"62px"}}):_vm._e(),(false && !_vm.$acl.check('doctor') && !_vm.$acl.check('hospital'))?_c('h-nav-menu',{class:[
          { 'text-white': _vm.isNavbarDark && !_vm.isThemeDark },
          { 'text-base': !_vm.isNavbarDark && _vm.isThemeDark },
        ],attrs:{"navMenuItems":_vm.navMenuItems}}):_vm._e()]:[_c('the-navbar-vertical',{class:[
          { 'text-white': _vm.isNavbarDark && !_vm.isThemeDark },
          { 'text-base': !_vm.isNavbarDark && _vm.isThemeDark },
        ],attrs:{"DoctorRate":_vm.DoctorRate,"navbarColor":_vm.navbarColor},on:{"OpenDoctorRate":function($event){_vm.showDoctorRate = true},"OpenHospitalRate":function($event){_vm.showHospitalRate = true}}})],_c('div',{staticClass:"content-wrapper"},[_c('div',{staticClass:"router-view"},[_c('div',{staticClass:"router-content"},[_c('div',{staticClass:"content-area__content"},[(!_vm.hideScrollToTop)?_c('back-to-top',{attrs:{"bottom":"5%","right":_vm.$vs.rtl ? 'calc(100% - 2.2rem - 38px)' : '30px',"visibleoffset":"500"}},[_c('vs-button',{staticClass:"shadow-lg btn-back-to-top",attrs:{"icon-pack":"feather","icon":"icon-arrow-up"}})],1):_vm._e(),_c('div',[(_vm.windowWidth >= 1200)?_c('div',{staticStyle:{"height":"40px","width":"100%","background":"rgb(235, 238, 243)","margin-top":"75px"}}):_vm._e(),_c('vs-row',{attrs:{"vs-w":"12"}},[_c('vs-col',{attrs:{"vs-type":"flex","vs-sm":"0","vs-w":_vm.$route.path != '/' ? 1 : 0}},[_c('ul',{staticStyle:{"height":"109vh","width":"100%"},attrs:{"id":"navbarStick"}},[(_vm.$route.path.toLowerCase() != '/patient/home')?_c('li',[_c('side-bar',{staticStyle:{"height":"48vh","margin-bottom":"5px"}})],1):_vm._e(),_c('li',[_c('side-bar',{style:({
                          height:
                            _vm.$route.path.toLowerCase() != '/patient/home'
                              ? '60vh'
                              : '107vh',
                        }),attrs:{"height":_vm.$route.path.toLowerCase() != '/patient/home'
                            ? 25
                            : 70,"title":_vm.$route.path != '/' ? _vm.$route.meta.pageTitle : ''}})],1)])]),_c('vs-col',{staticStyle:{"background":"white"},attrs:{"vs-type":"flex","vs-sm":"12","vs-w":_vm.$route.path != '/' ? 11 : 12}},[_c('div',{staticClass:"w-full",class:_vm.$route.path != '/' ? 'mr-2 ml-2' : ''},[_c('transition',{attrs:{"name":_vm.routerTransition,"mode":"out-in"}},[_c('router-view',{staticStyle:{"margin":"auto","background":"white"},style:([
                          _vm.$route.path != '/'
                            ? {
                                width: _vm.windowWidth >= 1200 ? '95%' : '100%',
                                'margin-top':
                                  _vm.windowWidth >= 1200 ? '2.1rem' : '5.2rem',
                                'padding-left':
                                  _vm.windowWidth >= 1200 ? '3rem' : '1.8rem',
                                'padding-right':
                                  _vm.windowWidth >= 1200 ? '3rem' : '1.8rem',
                                'padding-bottom': '3rem',
                              }
                            : {},
                        ]),on:{"changeRouteTitle":_vm.changeRouteTitle,"setAppClasses":(classesStr) => _vm.$emit('setAppClasses', classesStr)}})],1)],1)])],1)],1)],1)])])])],2),(_vm.showDoctorRate)?_c('vs-popup',{attrs:{"title":"","active":_vm.showDoctorRate},on:{"update:active":function($event){_vm.showDoctorRate=$event}}},[(_vm.showDoctorRate)?_c('doctorRate',{attrs:{"DoctorRate":_vm.DoctorRate},on:{"close":function($event){_vm.showDoctorRate = false}}}):_vm._e()],1):_vm._e(),(_vm.showHospitalRate)?_c('vs-popup',{attrs:{"title":"","active":_vm.showHospitalRate},on:{"update:active":function($event){_vm.showHospitalRate=$event}}},[(_vm.showHospitalRate)?_c('hospitalRate',{attrs:{"hospitalRate":_vm.DoctorRate},on:{"close":function($event){_vm.showHospitalRate = false}}}):_vm._e()],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }